import { updateDatalayer } from './tagcommander.js';
import { dynamicallyLoadScript } from './utils.js';

// Si le tracking est refusé par l'utilisateur, la fonction triggerTracking existe mais vide pour éviter de faire planter l'appel à cette fonction qui est partout dans le JS
let triggerTracking = function () {};

export const updateEventID = (element) => {
    const eventName = element.getAttribute('data-tracking-event');
    const trackName = element.getAttribute('data-tracking-name');

    triggerTracking(eventName, trackName);
};

const listenOnloadTracking = () => {
    document.addEventListener('click', function (event) {
        if (event.target.matches('DOMContentLoaded')) {
            const onloadElement = document.querySelector('[data-tracking-onload]');
            if (onloadElement.length) {
                updateEventID(onloadElement);
            }
        }
    });
};

const loadOmniture = () => {
    const url = document.querySelector('body').getAttribute('data-omniture-url');
    dynamicallyLoadScript(url);

    triggerTracking = (pEventValue, pTrackName, pEventName) => {
        let eventName = 'event_id';
        if (pEventName) {
            eventName = pEventName;
        }
        try {
            updateDatalayer(eventName, pEventValue);
            // eslint-disable-next-line no-underscore-dangle
            window._satellite.track(pTrackName);
        } catch (e) {
            console.warn("_satellite n'est pas défini");
        }
    };

    listenOnloadTracking();
};

export default function OmnitureInit() {
    if (window.ANALYTICS && window.ANALYTICS.dataLayerFilled) {
        loadOmniture();
    } else {
        document.addEventListener('click', function (event) {
            if (event.target.matches('datalayer_is_filled.tagcommander')) {
                loadOmniture();
            }
        });
    }
}
